export function gbStep5(_a) {
    var applicationHost = _a.applicationHost, defaultProductId = _a.defaultProductId;
    return [
        {
            componentId: 'titlePrivacyPolicy',
            testId: 'titlePrivacyPolicy',
            containerId: 'navigationTabsOnlineApproval_tab-content-4',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 6,
            order: 1,
            text: "<value data-text='general.privacy_policy_header'></value>",
            class: 'bss-label--step-navigation-title',
        },
        {
            componentId: 'privacyPolicyText',
            testId: 'privacyPolicyText',
            containerId: 'navigationTabsOnlineApproval_tab-content-4',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            class: 'bss-article-wrapper--step-navigation bss-mb-3',
            productId: [defaultProductId, 'general'],
            level: 6,
            order: 2,
            text: "<value data-text='general.privacy_policy_text'></value>",
        },
        {
            class: 'bss-component-container-wrapper--step-navigation',
            componentId: 'containerAllAccordions_' + defaultProductId,
            testId: 'containerAllAccordions',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-4',
            level: 6,
            order: 3,
            productId: [defaultProductId, 'general'],
        },
        {
            componentId: 'accordionContainer_01',
            testId: 'accordionContainer_01',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 1,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_01',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_01',
            testId: 'privatePolicyAccordion_01',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_01_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_01'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_01',
            testId: 'privatePolicyArticle_01',
            containerId: 'privatePolicyAccordion_01_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_01'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_02',
            testId: 'accordionContainer_02',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 2,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_02',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_02',
            testId: 'privatePolicyAccordion_02',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_02_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_02'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_02',
            testId: 'privatePolicyArticle_02',
            containerId: 'privatePolicyAccordion_02_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_02'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_03',
            testId: 'accordionContainer_03',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 3,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_03',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_03',
            testId: 'privatePolicyAccordion_03',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_03_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_02'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_03',
            testId: 'privatePolicyArticle_03',
            containerId: 'privatePolicyAccordion_03_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_03'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_04',
            testId: 'accordionContainer_04',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 4,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_04',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_04',
            testId: 'privatePolicyAccordion_04',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_04_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_04'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_04',
            testId: 'privatePolicyArticle_04',
            containerId: 'privatePolicyAccordion_04_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_04'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_05',
            testId: 'accordionContainer_05',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 5,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_05',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_05',
            testId: 'privatePolicyAccordion_05',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_05_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_05'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_05',
            testId: 'privatePolicyArticle_05',
            containerId: 'privatePolicyAccordion_05_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_05'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_06',
            testId: 'accordionContainer_06',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 6,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_06',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_06',
            testId: 'privatePolicyAccordion_06',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_06_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_06'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_06',
            testId: 'privatePolicyArticle_06',
            containerId: 'privatePolicyAccordion_06_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_06'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_07',
            testId: 'accordionContainer_07',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 7,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_07',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_07',
            testId: 'privatePolicyAccordion_07',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_07_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_07'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_07',
            testId: 'privatePolicyArticle_07',
            containerId: 'privatePolicyAccordion_07_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_07'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_08',
            testId: 'accordionContainer_08',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 8,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_08',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_08',
            testId: 'privatePolicyAccordion_08',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_08_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_08'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_08',
            testId: 'privatePolicyArticle_08',
            containerId: 'privatePolicyAccordion_08_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_08'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_09',
            testId: 'accordionContainer_09',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 9,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_09',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_09',
            testId: 'privatePolicyAccordion_09',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_09_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_09'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_09',
            testId: 'privatePolicyArticle_09',
            containerId: 'privatePolicyAccordion_09_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_09'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_10',
            testId: 'accordionContainer_10',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 10,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_10',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_10',
            testId: 'privatePolicyAccordion_10',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_10_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_10'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_10',
            testId: 'privatePolicyArticle_10',
            containerId: 'privatePolicyAccordion_10_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_10'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_11',
            testId: 'accordionContainer_11',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 11,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_11',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_11',
            testId: 'privatePolicyAccordion_11',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_11_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_11'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_11',
            testId: 'privatePolicyArticle_11',
            containerId: 'privatePolicyAccordion_11_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_11'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_12',
            testId: 'accordionContainer_12',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 12,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_12',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_12',
            testId: 'privatePolicyAccordion_12',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_12_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_12'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_12',
            testId: 'privatePolicyArticle_12',
            containerId: 'privatePolicyAccordion_12_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_12'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_13',
            testId: 'accordionContainer_13',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 13,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_13',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_13',
            testId: 'privatePolicyAccordion_13',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_13_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_13'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_13',
            testId: 'privatePolicyArticle_13',
            containerId: 'privatePolicyAccordion_13_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_13'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_14',
            testId: 'accordionContainer_14',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 14,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_14',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_14',
            testId: 'privatePolicyAccordion_14',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_14_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_14'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_14',
            testId: 'privatePolicyArticle_14',
            containerId: 'privatePolicyAccordion_14_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_14'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'accordionContainer_15',
            testId: 'accordionContainer_15',
            componentType: 'TemplateComponent',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 15,
            componentTemplate: 'grid',
            columns: ['bss-col-xs-12'],
            class: 'additional-class grid-class',
            watcher: [
                {
                    boundDataOperand: 'operandLeft',
                    checkGeneral: true,
                    effectSuccess: 'show',
                    effectFail: 'hide',
                    operandLeft: 'general.privacy_policy_header_15',
                    operator: 'is',
                },
            ],
        },
        {
            componentId: 'privatePolicyAccordion_15',
            testId: 'privatePolicyAccordion_15',
            componentType: 'AccordionComponent',
            containerId: 'accordionContainer_15_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            arrowIcon: '&#xe804',
            animDuration: 400,
            title: "<strong><value data-text='general.privacy_policy_header_15'></value></strong>",
        },
        {
            componentId: 'privatePolicyArticle_15',
            testId: 'privatePolicyArticle_15',
            containerId: 'privatePolicyAccordion_15_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.privacy_policy_text_15'></value>",
            valueText: true,
            class: 'bss-article--two-columns',
        },
        {
            componentId: 'checkboxPrivacyPolicy',
            testId: 'checkboxPrivacyPolicy',
            containerId: 'containerAllAccordions_' + defaultProductId + '_content',
            componentType: 'CheckboxComponent',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 16,
            onChangeOff: true,
            titleRequired: true,
            configuration: "<value data-checked='" +
                defaultProductId +
                ".components.checkboxComponents.privacy_policy.checked'></value>",
            text: "<value data-text='general.privacy_policy_checkbox_label'></value>",
            name: 'privacy_policy',
            checkboxAttributes: {
                'data-rule-required': true,
                'data-error-required': 'privacy_policy-error-required',
                name: 'privacy_policy',
                type: 'checkbox',
            },
            class: 'bss-checkbox--required bss-mt-3',
            checkboxErrors: [
                {
                    id: 'privacy_policy-error-required',
                    text: "<value data-text='general.error_empty_privacy_policy'></value>",
                },
            ],
        },
        {
            class: 'bss-component-container-wrapper--step-navigation',
            componentId: 'containerTermsAndConditions_' + defaultProductId,
            testId: 'containerTermsAndConditions',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-4',
            level: 6,
            order: 5,
            productId: [defaultProductId, 'general'],
        },
        {
            componentId: 'titleTermsAndConditions',
            testId: 'titleTermsAndConditions',
            containerId: 'containerTermsAndConditions_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 1,
            text: "<value data-text='general.terms_and_conditions_header'></value>",
            class: 'bss-label--step-navigation-title',
        },
        {
            componentId: 'termsAndConditionsText',
            testId: 'termsAndConditionsText',
            containerId: 'containerTermsAndConditions_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            class: 'bss-article-wrapper--step-navigation bss-mb-5',
            level: 7,
            order: 2,
            text: "<value data-text='general.terms_and_conditions_text'></value>",
        },
        {
            class: 'bss-component-container-wrapper--step-navigation',
            componentId: 'containerCommunications_' + defaultProductId,
            testId: 'containerCommunications',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-4',
            level: 6,
            order: 6,
            productId: [defaultProductId, 'general'],
        },
        {
            componentId: 'titleCommunications',
            testId: 'titleCommunications',
            containerId: 'containerCommunications_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 1,
            text: "<value data-text='general.communications_header'></value>",
            class: 'bss-label--step-navigation-title',
        },
        {
            componentId: 'communicationsText',
            testId: 'communicationsText',
            containerId: 'containerCommunications_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            class: 'bss-article-wrapper--step-navigation',
            level: 7,
            order: 2,
            text: "<value data-text='general.communications_text'></value>",
        },
        {
            componentId: 'communicationCategoriesText',
            testId: 'communicationCategoriesText',
            containerId: 'containerCommunications_' + defaultProductId + '_content',
            componentType: 'TemplateComponent',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 3,
            class: 'bss-mt-0 bss-mb-5 bss-summary-template-wrapper--step-navigation',
            text: "<value data-text='general.communication_categories_text'></value>",
            hintText: "<value data-text='general.communication_categories_infohint'></value>",
        },
        {
            columns: [
                'bss-col-xs-12 bss-mb-5',
                'bss-col-xs-12 bss-mb-5',
                'bss-col-xs-12 bss-mb-5',
                'bss-col-xs-12 bss-mb-5',
                'bss-col-xs-12 bss-mb-5',
            ],
            componentId: 'gridCommunicationCategories_' + defaultProductId,
            testId: 'gridCommunicationCategories',
            componentTemplate: 'grid',
            componentType: 'TemplateComponent',
            containerId: 'containerCommunications_' + defaultProductId + '_content',
            level: 7,
            order: 4,
            productId: [defaultProductId, 'general'],
        },
        {
            componentId: 'containerRadioReminders_' + defaultProductId,
            testId: 'containerRadioReminders',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'gridCommunicationCategories_' + defaultProductId + '_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            class: 'bss-component-container-wrapper--step-navigation',
        },
        {
            componentId: 'labelReminders_' + defaultProductId,
            testId: 'labelReminders',
            containerId: 'containerRadioReminders_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.communication_categories_reminders_radiobutton_label'></value>",
            value: "<value data-text='general.communication_categories_reminders_radiobutton_infohint'></value>",
            class: 'bss-label--required bss-label--step-navigation-title bss-m-0',
        },
        {
            componentId: 'radioYesReminders',
            testId: 'radioYesReminders',
            containerId: 'containerRadioReminders_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            configuration: "<value data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_categories_reminders.value'></value>",
            text: 'Yes',
            groupName: 'communication_categories_reminders',
            value: 'yes',
            anotherDesign: true,
            onChangeOff: true,
            radioAttributes: {
                'data-associated-target': true,
                'data-rule-required': true,
                'data-error-required': 'reminder-error-required',
            },
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'radioNoReminders',
            testId: 'radioNoReminders',
            containerId: 'containerRadioReminders_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            configuration: "<value data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_categories_reminders.value'></value>",
            text: 'No',
            groupName: 'communication_categories_reminders',
            value: 'no',
            anotherDesign: true,
            onChangeOff: true,
            radioAttributes: {
                'data-associated-target': true,
                'data-rule-required': true,
                'data-error-required': 'reminder-error-required',
            },
            radioErrors: [
                {
                    id: 'reminder-error-required',
                    text: "<value data-text='general.error_empty_gender'></value>",
                },
            ],
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'containerRadioRelevantOffers_' + defaultProductId,
            testId: 'containerRadioRelevantOffers',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'gridCommunicationCategories_' + defaultProductId + '_col-1',
            level: 8,
            order: 2,
            productId: [defaultProductId, 'general'],
            class: 'bss-component-container-wrapper--step-navigation',
        },
        {
            componentId: 'labelRelevantOffers_' + defaultProductId,
            testId: 'labelRelevantOffers',
            containerId: 'containerRadioRelevantOffers_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.communication_categories_offers_radiobutton_label'></value>",
            value: "<value data-text='general.communication_categories_offers_radiobutton_infohint'></value>",
            class: 'bss-label--required bss-label--step-navigation-title bss-m-0',
        },
        {
            componentId: 'radioYesRelevantOffers',
            testId: 'radioYesRelevantOffers',
            containerId: 'containerRadioRelevantOffers_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            configuration: "<value  data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_categories_offers.value'></value>",
            text: 'Yes',
            groupName: 'communication_categories_offers',
            value: 'yes',
            anotherDesign: true,
            onChangeOff: true,
            radioAttributes: {
                'data-associated-target': true,
                'data-rule-required': true,
                'data-error-required': 'relevant-offers-error-required',
            },
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'radioNoRelevantOffers',
            testId: 'radioNoRelevantOffers',
            containerId: 'containerRadioRelevantOffers_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            configuration: "<value data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_categories_offers.value'></value>",
            text: 'No',
            groupName: 'communication_categories_offers',
            value: 'no',
            anotherDesign: true,
            onChangeOff: true,
            radioAttributes: {
                'data-associated-target': true,
                'data-rule-required': true,
                'data-error-required': 'relevant-offers-error-required',
            },
            radioErrors: [
                {
                    id: 'relevant-offers-error-required',
                    text: "<value data-text='general.error_empty_gender'></value>",
                },
            ],
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'containerSurveys_' + defaultProductId,
            testId: 'containerSurveys',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'gridCommunicationCategories_' + defaultProductId + '_col-2',
            level: 8,
            order: 3,
            productId: [defaultProductId, 'general'],
            class: 'bss-component-container-wrapper--step-navigation',
        },
        {
            componentId: 'labelSurveys_' + defaultProductId,
            testId: 'labelSurveys',
            containerId: 'containerSurveys_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.communication_categories_surveys_radiobutton_label'></value>",
            value: "<value data-text='general.communication_categories_surveys_radiobutton_infohint'></value>",
            class: 'bss-label--required bss-label--step-navigation-title bss-m-0',
        },
        {
            componentId: 'radioYesSurveys',
            testId: 'radioYesSurveys',
            containerId: 'containerSurveys_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            configuration: "<value data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_categories_surveys.value'></value>",
            text: 'Yes',
            groupName: 'communication_categories_surveys',
            value: 'yes',
            anotherDesign: true,
            onChangeOff: true,
            radioAttributes: {
                'data-associated-target': true,
                'data-rule-required': true,
                'data-error-required': 'surveys-error-required',
            },
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'radioNoSurveys',
            testId: 'radioNoSurveys',
            containerId: 'containerSurveys_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            configuration: "<value data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_categories_surveys.value'></value>",
            text: 'No',
            groupName: 'communication_categories_surveys',
            value: 'no',
            anotherDesign: true,
            onChangeOff: true,
            radioAttributes: {
                'data-associated-target': true,
                'data-rule-required': true,
                'data-error-required': 'surveys-error-required',
            },
            radioErrors: [
                {
                    id: 'surveys-error-required',
                    text: "<value data-text='general.error_empty_gender'></value>",
                },
            ],
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'containerEvents_' + defaultProductId,
            testId: 'containerEvents',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'gridCommunicationCategories_' + defaultProductId + '_col-3',
            level: 8,
            order: 4,
            productId: [defaultProductId, 'general'],
            class: 'bss-component-container-wrapper--step-navigation',
        },
        {
            componentId: 'labelEvents_' + defaultProductId,
            testId: 'labelEvents',
            containerId: 'containerEvents_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.communication_categories_events_radiobutton_label'></value>",
            value: "<value data-text='general.communication_categories_events_radiobutton_infohint'></value>",
            class: 'bss-label--required bss-label--step-navigation-title bss-m-0',
        },
        {
            componentId: 'radioYesEvents',
            testId: 'radioYesEvents',
            containerId: 'containerEvents_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            configuration: "<value data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_categories_events.value'></value>",
            text: 'Yes',
            groupName: 'communication_categories_events',
            value: 'yes',
            anotherDesign: true,
            onChangeOff: true,
            radioAttributes: {
                'data-associated-target': true,
                'data-rule-required': true,
                'data-error-required': 'events-error-required',
            },
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'radioNoEvents',
            testId: 'radioNoEvents',
            containerId: 'containerEvents_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            configuration: "<value data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_categories_events.value'></value>",
            text: 'No',
            groupName: 'communication_categories_events',
            value: 'no',
            anotherDesign: true,
            onChangeOff: true,
            radioAttributes: {
                'data-associated-target': true,
                'data-rule-required': true,
                'data-error-required': 'events-error-required',
            },
            radioErrors: [
                {
                    id: 'events-error-required',
                    text: "<value data-text='general.error_empty_gender'></value>",
                },
            ],
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'containerCommunicationText_' + defaultProductId,
            testId: 'containerCommunicationText',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'gridCommunicationCategories_' + defaultProductId + '_col-4',
            level: 8,
            order: 5,
            productId: [defaultProductId, 'general'],
            class: 'bss-component-container-wrapper--step-navigation',
        },
        {
            componentId: 'labelCommunicationCategoriesSummary_' + defaultProductId,
            testId: 'labelCommunicationCategoriesSummary',
            containerId: 'containerCommunicationText_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: '',
            value: "<value data-text='general.communication_categories_summary'></value>",
            class: 'bss-label--step-navigation-description bss-label--step-navigation-description-bordered',
        },
        {
            class: 'bss-error-message error-message is-invalid bss-watcher-hide',
            componentId: 'targetValidationErrorLabel',
            testId: 'targetValidationErrorLabel',
            componentType: 'LabelComponent',
            containerId: 'containerCommunicationText_' + defaultProductId + '_content',
            level: 9,
            order: 2,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_invalid_categories_agreements'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-agreements-error',
                },
            ],
        },
        {
            class: 'bss-component-container-wrapper--step-navigation',
            componentId: 'containerCommunicationMethodPreferences_' + defaultProductId,
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-4',
            level: 6,
            order: 7,
            productId: [defaultProductId, 'general'],
        },
        {
            componentId: 'titleCommunicationAgreements',
            testId: 'titleCommunicationAgreements',
            containerId: 'containerCommunicationMethodPreferences_' +
                defaultProductId +
                '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 7,
            order: 1,
            text: "<value data-text='general.communication_agreements_header'></value>",
            class: 'bss-label--step-navigation-title',
        },
        {
            componentId: 'communicationAgreementsText',
            testId: 'communicationAgreementsText',
            containerId: 'containerCommunicationMethodPreferences_' +
                defaultProductId +
                '_content',
            componentType: 'TemplateComponent',
            componentTemplate: 'articleTemplate',
            productId: [defaultProductId, 'general'],
            class: 'bss-article-wrapper--step-navigation bss-mb-3',
            level: 7,
            order: 2,
            text: "<value data-text='general.communication_agreements_text'></value>",
        },
        {
            columns: [
                'bss-col-xs-12 bss-mb-4',
                'bss-col-xs-12 bss-mb-4',
                'bss-col-xs-12 bss-mb-4',
                'bss-col-xs-12',
            ],
            componentId: 'gridCommunicationAgreements_' + defaultProductId,
            testId: 'gridCommunicationAgreements',
            componentTemplate: 'grid',
            componentType: 'TemplateComponent',
            containerId: 'containerCommunicationMethodPreferences_' +
                defaultProductId +
                '_content',
            level: 7,
            order: 3,
            productId: [defaultProductId, 'general'],
            class: 'bss-grid--no-padding',
        },
        {
            class: 'bss-error-message error-message is-invalid bss-watcher-hide',
            componentId: 'agreementValidationErrorLabel',
            testId: 'agreementValidationErrorLabel',
            componentType: 'LabelComponent',
            containerId: 'containerCommunicationMethodPreferences_' +
                defaultProductId +
                '_content',
            level: 7,
            order: 4,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_invalid_categories_categories'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-categories-error',
                },
            ],
        },
        {
            componentId: 'containerRadioPhone_' + defaultProductId,
            testId: 'containerRadioPhone',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'gridCommunicationAgreements_' + defaultProductId + '_col-0',
            level: 8,
            order: 1,
            productId: [defaultProductId, 'general'],
            class: 'bss-component-container-wrapper--step-navigation',
        },
        {
            componentId: 'labelPhone_' + defaultProductId,
            testId: 'labelPhone',
            containerId: 'containerRadioPhone_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.communication_agreements_is_contactable_via_phone_radiobutton_label'></value>",
            value: '',
            class: 'bss-label--required bss-label--step-navigation-tiny-title bss-m-0',
        },
        {
            componentId: 'radioYesPhone',
            testId: 'radioYesPhone',
            containerId: 'containerRadioPhone_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            configuration: "<value  data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_agreements_is_contactable_via_phone.value'></value>",
            text: 'Yes',
            groupName: 'communication_agreements_is_contactable_via_phone',
            value: 'yes',
            anotherDesign: true,
            onChangeOff: true,
            class: 'communicationAgreements',
            radioAttributes: {
                'data-associated-agreement': true,
                'data-rule-required': true,
                'data-error-required': 'phone-error-required',
            },
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'radioNoPhone',
            testId: 'radioNoPhone',
            containerId: 'containerRadioPhone_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            configuration: "<value data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_agreements_is_contactable_via_phone.value'></value>",
            text: 'No',
            groupName: 'communication_agreements_is_contactable_via_phone',
            value: 'no',
            anotherDesign: true,
            onChangeOff: true,
            class: 'communicationAgreements',
            radioAttributes: {
                'data-associated-agreement': true,
                'data-rule-required': true,
                'data-error-required': 'phone-error-required',
            },
            radioErrors: [
                {
                    id: 'phone-error-required',
                    text: "<value data-text='general.error_empty_gender'></value>",
                },
            ],
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'containerRadioEmail_' + defaultProductId,
            testId: 'containerRadioEmail',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'gridCommunicationAgreements_' + defaultProductId + '_col-1',
            level: 8,
            order: 2,
            productId: [defaultProductId, 'general'],
            class: 'bss-component-container-wrapper--step-navigation',
        },
        {
            componentId: 'labelEmail_' + defaultProductId,
            testId: 'labelEmail',
            containerId: 'containerRadioEmail_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.communication_agreements_is_contactable_via_email_radiobutton_label'></value>",
            value: '',
            class: 'bss-label--required bss-label--step-navigation-tiny-title bss-m-0',
        },
        {
            componentId: 'radioYesEmail',
            testId: 'radioYesEmail',
            containerId: 'containerRadioEmail_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            configuration: "<value data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_agreements_is_contactable_via_email.value'></value>",
            text: 'Yes',
            groupName: 'communication_agreements_is_contactable_via_email',
            value: 'yes',
            anotherDesign: true,
            onChangeOff: true,
            class: 'communicationAgreements',
            radioAttributes: {
                'data-associated-agreement': true,
                'data-rule-required': true,
                'data-error-required': 'email-error-required',
            },
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'radioNoEmail',
            testId: 'radioNoEmail',
            containerId: 'containerRadioEmail_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            configuration: "<value data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_agreements_is_contactable_via_email.value'></value>",
            text: 'No',
            groupName: 'communication_agreements_is_contactable_via_email',
            value: 'no',
            anotherDesign: true,
            onChangeOff: true,
            class: 'communicationAgreements',
            radioAttributes: {
                'data-associated-agreement': true,
                'data-rule-required': true,
                'data-error-required': 'email-error-required',
            },
            radioErrors: [
                {
                    id: 'email-error-required',
                    text: "<value data-text='general.error_empty_gender'></value>",
                },
            ],
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'containerRadioSms_' + defaultProductId,
            testId: 'containerRadioSms',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'gridCommunicationAgreements_' + defaultProductId + '_col-2',
            level: 8,
            order: 3,
            productId: [defaultProductId, 'general'],
            class: 'bss-component-container-wrapper--step-navigation',
        },
        {
            componentId: 'labelSms_' + defaultProductId,
            testId: 'labelSms',
            containerId: 'containerRadioSms_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.communication_agreements_is_contactable_via_sms_radiobutton_label'></value>",
            value: '',
            class: 'bss-label--required bss-label--step-navigation-tiny-title bss-m-0',
        },
        {
            componentId: 'radioYesSms',
            testId: 'radioYesSms',
            containerId: 'containerRadioSms_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            configuration: "<value data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_agreements_is_contactable_via_sms.value'></value>",
            text: 'Yes',
            groupName: 'communication_agreements_is_contactable_via_sms',
            value: 'yes',
            anotherDesign: true,
            onChangeOff: true,
            class: 'communicationAgreements',
            radioAttributes: {
                'data-associated-agreement': true,
                'data-rule-required': true,
                'data-error-required': 'sms-error-required',
            },
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'radioNoSms',
            testId: 'radioNoSms',
            containerId: 'containerRadioSms_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            configuration: "<value  data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_agreements_is_contactable_via_sms.value'></value>",
            text: 'No',
            groupName: 'communication_agreements_is_contactable_via_sms',
            value: 'no',
            anotherDesign: true,
            onChangeOff: true,
            class: 'communicationAgreements',
            radioAttributes: {
                'data-associated-agreement': true,
                'data-rule-required': true,
                'data-error-required': 'sms-error-required',
            },
            radioErrors: [
                {
                    id: 'sms-error-required',
                    text: "<value data-text='general.error_empty_gender'></value>",
                },
            ],
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'containerRadioPost_' + defaultProductId,
            testId: 'containerRadioPost',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'gridCommunicationAgreements_' + defaultProductId + '_col-3',
            level: 8,
            order: 4,
            productId: [defaultProductId, 'general'],
            class: 'bss-component-container-wrapper--step-navigation',
        },
        {
            componentId: 'labelPost_' + defaultProductId,
            testId: 'labelPost',
            containerId: 'containerRadioPost_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 1,
            text: "<value data-text='general.communication_agreements_is_contactable_via_post_radiobutton_label'></value>",
            value: '',
            class: 'bss-label--required bss-label--step-navigation-tiny-title bss-m-0',
        },
        {
            componentId: 'radioYesPost',
            testId: 'radioYesPost',
            containerId: 'containerRadioPost_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 2,
            configuration: "<value  data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_agreements_is_contactable_via_post.value'></value>",
            text: 'Yes',
            groupName: 'communication_agreements_is_contactable_via_post',
            value: 'yes',
            anotherDesign: true,
            onChangeOff: true,
            class: 'communicationAgreements',
            radioAttributes: {
                'data-associated-agreement': true,
                'data-rule-required': true,
                'data-error-required': 'post-error-required',
            },
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
        {
            componentId: 'radioNoPost',
            testId: 'radioNoPost',
            containerId: 'containerRadioPost_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 9,
            order: 3,
            configuration: "<value  data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.communication_agreements_is_contactable_via_post.value'></value>",
            text: 'No',
            groupName: 'communication_agreements_is_contactable_via_post',
            value: 'no',
            anotherDesign: true,
            onChangeOff: true,
            class: 'communicationAgreements',
            radioAttributes: {
                'data-associated-agreement': true,
                'data-rule-required': true,
                'data-error-required': 'post-error-required',
            },
            radioErrors: [
                {
                    id: 'post-error-required',
                    text: "<value data-text='general.error_empty_gender'></value>",
                },
            ],
            associatedErrorIds: {
                target: '#targetValidationErrorLabel',
                agreement: '#agreementValidationErrorLabel',
            },
        },
    ];
}
